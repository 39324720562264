<nav class="menubar">
  <div class="logo">
      <img src="assets/all-white-logo-dm.png" width="60" alt="logo">
  </div>
  <div class="burger" (click)="toggleSideNav()" *ngIf="isBurgerVisible">
    <i class="fas fa-bars"></i>
  </div>
  <ul class="navigation" [ngClass]="{'show-nav': isNavVisible}">
    <li (click)="toLandingPage()" [ngClass]="{ 'active': isLinkActive('/') }">
      <a routerLink="/landing-page">Home</a>
    </li>
    <li (click)="toAboutPage()" [ngClass]="{ 'active': isLinkActive('/about') }">
      <a routerLink="/about">About</a>
    </li>
    <li (click)="toExperiencePage()" [ngClass]="{ 'active': isLinkActive('/experience') }">
      <a routerLink="/experience">Experience</a>
    </li>
    <li (click)="toSkillsPage()" [ngClass]="{ 'active': isLinkActive('/skills') }">
      <a routerLink="/skills">Skills</a>
    </li>
    <li (click)="toProjectsPage()" [ngClass]="{ 'active': isLinkActive('/projects') }">
      <a routerLink="/projects">Projects</a>
    </li>
    <li (click)="toContributionsPage()" [ngClass]="{ 'active': isLinkActive('/contributions') }">
      <a routerLink="/contributions">Contributions</a>
    </li>
    <li (click)="toContactsPage()" [ngClass]="{ 'active': isLinkActive('/contact') }">
      <a routerLink="/contact">Contact</a>
    </li>
  </ul>
</nav>
