<!-- Created based on Youtube [CodingLab](https://www.youtube.com/@CodingLabYT)--> 
<!-- Need an individual APIkey from OpenAI to make the bot work. it's free but with limitations-->

<!-- Icon  -->
<link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0"
    />
<!-- Code :) -->

<div id="chatBubble" class="chatBubble">
  <p>Got a question? Ask me!</p>
</div>

<button id="chatbotButton" class="chatbot__button" (click)="toggleChatBox()">
  <span class="material-symbols-outlined" *ngIf="!showChatBox">mode_comment</span>
  <span class="material-symbols-outlined" *ngIf="showChatBox">close</span>
</button>
<div [class.chatbot-active]="showChatBox" class="chatbot" style="z-index: 9999;">
  <div class="chatbot__header">
    <h3 class="chatbox__title">Chat Assistant</h3>
    <span class="material-symbols-outlined" (click)="toggleChatBox()">close</span>
  </div>

  <div class="chatbot__input-box">
    <span id="send-btn" class="material-symbols-outlined">send</span>
  </div>

  <iframe
    src="https://www.chatbase.co/chatbot-iframe/HBgxEs-Hf9mCeggwaTUnb"
    width="100%"
    style="height: 100%; min-height: 550px;"
    frameborder="1"
  ></iframe>
</div>
