
<div class="contribution-page">

    <h1 style="font-size: 3.5em; text-align: center; padding: 100px; color: #343434;">My Blog Posts</h1>

    <div class="row">
      <div class="col-4">
        <div class="component-card">
          <div class="component-card_image">
            <div class="component-card_image-inside">
              <img src="assets/pexels-olia-danilevich-4974915.jpg" alt="" title="" style="" />
            </div>
          </div>
          <div class="blog-detail">
            <h3>My Experience as a Self-Taught Developer: Unlocking the Path to Success</h3>
            <label>December 4, 2019</label>
            <p>In the rapidly evolving technology landscape, I found my calling as a self-taught developer. Facing initial skepticism due to my lack of formal education, I utilized online resources to master programming languages like Python, JavaScript, and HTML/CSS. This journey involved embracing challenges as opportunities, cultivating a problem-solving mindset, and fostering resilience. </p>
            <a class="btn btn-read-more" href="#">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="component-card">
          <div class="component-card_image">
            <div class="component-card_image-inside">
              <img src="assets/pexels-shvets-production-7176305.jpg" alt="" title="" />
            </div>
          </div>
          <div class="blog-detail">
            <h3>Embracing Authenticity: Overcoming the Burden of Others' Opinions</h3>
            <label>December 4, 2019</label>
            <p>In a society where judgments and expectations can often dictate our actions, this article explores how adopting a mindset of indifference to others' opinions can lead to a more fulfilling and authentic life. Growing up, we're conditioned to seek validation, allowing the views of family, friends, and strangers to dictate our self-worth. This dependence on external approval, however, can trap us in a cycle of comparison and self-doubt.</p>
            <a class="btn btn-read-more" href="#">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="component-card">
          <div class="component-card_image">
            <div class="component-card_image-inside">
              <img src="assets/pexels-andrea-piacquadio-3761504.jpg" alt="" title="" style="" />
            </div>
          </div>
          <div class="blog-detail">
            <h3>The Road to Success: Embracing Sacrifice for a Brighter Future</h3>
            <label>December 4, 2019</label>
            <p>In this article, the road to success is dissected, particularly focusing on the inherent role of sacrifice. I learned that success doesn't simply fall into our laps; it's the product of commitment, effort, and often, difficult choices. Remarkable achievements aren't reached without surrendering some comforts along the way.</p>
            <a class="btn btn-read-more" href="#">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
