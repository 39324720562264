<link rel="stylesheet" href="https://unpkg.com/github-calendar@latest/dist/github-calendar-responsive.css">

<div class="contribution-page">
  <!-- <a href="https://github.com/DarrelMendoza" style="text-decoration: none;">
    <h1 style="font-size: 48px;">Realtime Github Contribution Graph</h1>
  </a> -->
  <div class="h1container">
    <h1 style="font-size: 48px;">Daily Contributions</h1>
  </div>
    <div class="graph">
      <!-- <a href="https://github.com/DarrelMendoza" style="text-decoration: none; color: #fff;">
        <div id="calendar"></div>
      </a> -->
      <img src="https://ghchart.rshah.org/DarrelMendoza" alt="DarrelMendoza's Github chart" />
    </div>
  <div class="description">
      <p style="font-weight: bold; font-family: 'Inter-SemiBold';">REALTIME GITHUB CONTRIBUTION CALENDAR</p>
      <p>Created using Angular</p>
      <p>and GitHub API Call.</p>
      <p style="color: #343434; font-family: 'Inter-SemiBold'; font-weight: bold; font-style: italic;"><a href="https://github.com/DarrelMendoza" target="_blank">View Github Account</a></p>
  </div>
</div>
