<div class="splashBody">
	<div class="splashScreen">
		<div class="loadingContainer">

			<div class="loadingBox">
				<img src="https://vignette.wikia.nocookie.net/borderlands/images/4/42/Vault_logo.png/revision/latest?cb=20100114181536" class="splashLogo">
				<div class="loadingBarContainer">
					<div class="loadingbar"></div>
				</div>

				<div class="loadingCircle">
					<div class="circleOuter"></div>
					<div class="circleLoader"></div>
				</div>
			</div>


		</div>
	</div>
</div>
