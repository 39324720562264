<app-navbar></app-navbar>

<section id="landing-page">
    <app-landing-page></app-landing-page>
</section>

<section id="about">
    <app-about-page></app-about-page>
</section>

<section id="experience">
    <app-experience-page></app-experience-page>
</section>

<section id="skills">
    <app-skills></app-skills>
</section>

<section id="projects">
    <app-projects-page></app-projects-page>
    <!-- <app-github-graph></app-github-graph> -->
</section>

<section id="contributions">
    <!-- <app-blogs-page></app-blogs-page> -->
    <app-github-graph></app-github-graph>
</section>

<section id="contact">
    <app-footer></app-footer>
</section>


<app-chatbot></app-chatbot>
