<!-- Component template -->
<link rel="stylesheet" href="https://unpkg.com/github-calendar@latest/dist/github-calendar-responsive.css">

<div class="sample-projects-container">
  <!-- Header -->
  <h1 class="header">Recent Projects</h1>

  <div class="container">
    <div class="card" (click)="openModal(0)">
        <div class="face face1" style="background-color: #3ebaad !important;">
            <div class="content">
              <img src="./assets/postnest-white.png" alt="Image 1">
              <h3>POSTNEST <br> Social Media Site</h3>
            </div>
        </div>
        <div class="face face2">
            <div class="content">
              <p>PostNest is a social platform where users share and connect over spontaneous thoughts
              </p>
              <div class="btn" style="background-color: #3ebaad; color: #fff;" (click)="openModal(0)">LEARN MORE</div>
            </div>
        </div>
    </div>
    <div class="card" (click)="openModal(1)">
        <div class="face face1" style="background-color: #0687ff !important">
            <div class="content">
              <img src="./assets/badbeat-white.png" alt="Image 1">
                <h3>BADBEAT <br> E-Commerce Website</h3>
            </div>
        </div>
        <div class="face face2">
            <div class="content">
                <p>An e-commerce platform designed to seamlessly connect businesses with their target audiences, offering an intuitive online shopping experience.
                </p>
                <div class="btn" style="background-color: #0687ff; color: #fff;" (click)="openModal(1)">LEARN MORE</div>
            </div>
        </div>
    </div>
    <div class="card" (click)="openModal(2)">
      <div class="face face1" style="background-color: #5a5a5a !important">
          <div class="content">
            <img src="./assets/budgetbuddy-white.png" alt="Image 1">
              <h3>BUDGETBUDDY <br>Finance Manager</h3>
          </div>
      </div>
      <div class="face face2">
          <div class="content">
              <p>A finance manager app designed to empower individuals with interactive tools for streamlined budgeting, expense tracking, and insightful financial planning.
              </p>
              <div class="btn" style="background-color: #5a5a5a; color: #fff;" (click)="openModal(2)">LEARN MORE</div>
          </div>
      </div>
  </div>
  </div>
</div>

<!-- This is your modal -->
<div id="myModal" class="modal" tabindex="-1">
  <div class="modal-content">
    <!-- Add your modal content here -->
    <!-- The x button is placed here -->
    <div class="close" (click)="closeModal()">&times;</div>
    
    <div class="grid product">
      <div class="column-xs-12 column-md-7 imageslist">
        <div class="product-gallery">
          <div class="product-image">
            <!-- Here we bind the currentImage to the img src -->
            <img class="active" [src]="currentImage">
          </div>
          <!-- We use *ngFor to iterate over the images array and ngClass to add the 'active' class to the currentImage -->
          <ul class="image-list">
            <li class="image-item" *ngFor="let img of projectImages[currentProjectIndex]; let i = index" (click)="changeImage(i)">
              <img [src]="img" [ngClass]="{'active': i === selectedImageIndex}">
            </li>
          </ul>
        </div>
      </div>
      <div class="column-xs-12 column-md-5 descriptionlist">
        <h1>{{ projectNames[currentProjectIndex] }}</h1>
        <h2 style="font-size: 20px;">{{ projectTechnologies[currentProjectIndex] }}</h2>
        <div class="description">
          <p [innerHTML]="projectDescriptions[currentProjectIndex]"></p>
        </div>
        <a [href]="projectRepositories[currentProjectIndex]" target="_blank">
          <button class="add-to-cart">View Repository</button>
        </a>
      </div>
    </div>
  </div>
</div>

