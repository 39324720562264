
    <!-- <app-navbar></app-navbar>
    <app-landing-page></app-landing-page>
    <app-about-page></app-about-page>
    <app-github-graph></app-github-graph>
    <app-projects-page></app-projects-page>
    <app-chatbot></app-chatbot>
    <app-footer></app-footer> -->
    <app-home></app-home>

  
  