<link rel="stylesheet" href="https://unpkg.com/github-calendar@latest/dist/github-calendar-responsive.css">
<meta name="viewport" content="width=device-width, initial-scale=1">

<div class="skills-page">
  <div class="skills-content">
    <p style="font-size: 13px;">TECHNOLOGIES USED FOR DEVELOPMENT</p>
        <div class="h1container">
            <h1 style="font-size: 48px;">Skills & Proficiencies</h1>
        </div>
        <div class="icon-set" style="padding-right: 10px;">
            <div class="logos">
                <div class="logo-details">
                    <h1 class="role">FRONT END DEVELOPMENT</h1>
                    <div class="social-icons">
                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--html">
                                    <img src="assets/icons/html.png" alt="Icon" class="icon">
                                    <div class="tooltip">HTML</div>
                                </a>
                            </div>
                            <div class="title">HTML</div>
                          </div>

                          <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--css">
                                    <img src="assets/icons/css.png" alt="Icon" class="icon">
                                    <div class="tooltip">CSS</div>
                                </a>
                            </div>
                            <div class="title">CSS</div>
                          </div>

                        <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--javascript">
                                <img src="assets/icons/javascript.png" alt="Icon" class="icon">
                                <div class="tooltip">JavaScript</div>
                            </a>
                        </div>
                        <div class="title">JAVASCRIPT</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--react">
                                    <img src="assets/icons/react.png" alt="Icon" class="icon">
                                    <div class="tooltip">REACT</div>
                                </a>
                            </div>
                            <div class="title">REACT</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--angular">
                                    <img src="assets/icons/angular.png" alt="Icon" class="icon">
                                    <div class="tooltip">ANGULAR</div>
                                </a>
                            </div>
                            <div class="title">ANGULAR</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--ionic">
                                    <img src="assets/icons/ionic.png" alt="Icon" class="icon">
                                    <div class="tooltip">IONIC</div>
                                </a>
                            </div>
                            <div class="title">IONIC</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--jasper">
                                    <img src="assets/icons/jasper.png" alt="Icon" class="icon">
                                    <div class="tooltip">JASPER REPORTS</div>
                                </a>
                            </div>
                            <div class="title">JASPER REPORTS</div>
                        </div>

                      </div>
                </div>
            </div>
        </div>
        <div class="icon-set" style="padding-right: 10px;">
            <div class="logos">
                <div class="logo-details">
                    <div class="social-icons">
                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--bootstrap">
                                    <img src="assets/icons/bootstrap.png" alt="Icon" class="icon">
                                    <div class="tooltip">BOOTSTRAP</div>
                                </a>
                            </div>
                            <div class="title">BOOTSTRAP</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--reactnative">
                                    <img src="assets/icons/reactnative.png" alt="Icon" class="icon">
                                    <div class="tooltip">REACT NATIVE</div>
                                </a>
                            </div>
                            <div class="title">REACT NATIVE</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--tailwind">
                                    <img src="assets/icons/tailwind.png" alt="Icon" class="icon">
                                    <div class="tooltip">TAILWIND</div>
                                </a>
                            </div>
                            <div class="title">TAILWIND</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--sass">
                                    <img src="assets/icons/sass.png" alt="Icon" class="icon">
                                    <div class="tooltip">SASS</div>
                                </a>
                            </div>
                            <div class="title">SASS</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--less">
                                    <img src="assets/icons/less.png" alt="Icon" class="icon">
                                    <div class="tooltip">LESS</div>
                                </a>
                            </div>
                            <div class="title">LESS</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--jquery">
                                    <img src="assets/icons/jquery.png" alt="Icon" class="icon">
                                    <div class="tooltip">JQUERY</div>
                                </a>
                            </div>
                            <div class="title">JQUERY</div>
                        </div>

                        <div class="box">
                            <div class="image">
                                <a class="social-icon social-icon--chartjs">
                                    <img src="assets/icons/chartjs.png" alt="Icon" class="icon">
                                    <div class="tooltip">CHARTJS</div>
                                </a>
                            </div>
                            <div class="title">CHARTJS</div>
                        </div>

                      </div>
                </div>
            </div>
        </div>
  </div>
  <div class="skills-content2">
    <div class="icon-set" style="padding-right: 10px;">
        <div class="logos">
            <div class="logo-details">
                <h1 class="role">BACK END DEVELOPMENT</h1>
                <div class="social-icons">

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--typescript">
                                <img src="assets/icons/typescript.png" alt="Icon" class="icon">
                                <div class="tooltip">TYPESCRIPT</div>
                            </a>
                        </div>
                        <div class="title">TYPESCRIPT</div>
                    </div>

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--php">
                                <img src="assets/icons/php.png" alt="Icon" class="icon">
                                <div class="tooltip">PHP</div>
                            </a>
                        </div>
                        <div class="title">PHP</div>
                    </div>

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--laravel">
                                <img src="assets/icons/laravel.png" alt="Icon" class="icon">
                                <div class="tooltip">LARAVEL</div>
                            </a>
                        </div>
                        <div class="title">LARAVEL</div>
                    </div>

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--java">
                                <img src="assets/icons/java.png" alt="Icon" class="icon">
                                <div class="tooltip">JAVA</div>
                            </a>
                        </div>
                        <div class="title">JAVA</div>
                    </div>

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--aws">
                                <img src="assets/icons/aws.png" alt="Icon" class="icon">
                                <div class="tooltip">AWS</div>
                            </a>
                        </div>
                        <div class="title">AWS</div>
                    </div>

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--mysql">
                                <img src="assets/icons/mysql.png" alt="Icon" class="icon">
                                <div class="tooltip">MYSQL</div>
                            </a>
                        </div>
                        <div class="title">MYSQL</div>
                    </div>


                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--postgresql">
                                <img src="assets/icons/postgresql.png" alt="Icon" class="icon">
                                <div class="tooltip">POSTGRESQL</div>
                            </a>
                        </div>
                        <div class="title">POSTGRESQL</div>
                    </div>

                  </div>
            </div>
        </div>
    </div>
    <div class="icon-set" style="padding-right: 10px;">
        <div class="logos">
            <div class="logo-details">
                <div class="social-icons">

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--nodejs">
                                <img src="assets/icons/nodejs.png" alt="Icon" class="icon">
                                <div class="tooltip">NODE JS</div>
                            </a>
                        </div>
                        <div class="title">NODE JS</div>
                    </div>


                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--plsql">
                                <img src="assets/icons/plsql.png" alt="Icon" class="icon">
                                <div class="tooltip">PL/SQL</div>
                            </a>
                        </div>
                        <div class="title">PL/SQL</div>
                    </div>

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--talend">
                                <img src="assets/icons/talend.png" alt="Icon" class="icon">
                                <div class="tooltip">TALEND</div>
                            </a>
                        </div>
                        <div class="title">TALEND</div>
                    </div>

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--oracleapex">
                                <img src="assets/icons/oracleapex.png" alt="Icon" class="icon">
                                <div class="tooltip">ORACLE APEX</div>
                            </a>
                        </div>
                        <div class="title">ORACLE APEX</div>
                    </div>



                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--vagrant">
                                <img src="assets/icons/vagrant.png" alt="Icon" class="icon">
                                <div class="tooltip">VAGRANT</div>
                            </a>
                        </div>
                        <div class="title">VAGRANT</div>
                    </div>

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--phpmyadmin">
                                <img src="assets/icons/phpmyadmin.png" alt="Icon" class="icon">
                                <div class="tooltip">PHPMYADMIN</div>
                            </a>
                        </div>
                        <div class="title">PHPMYADMIN</div>
                    </div>

                    <div class="box">
                        <div class="image">
                            <a class="social-icon social-icon--sqlite">
                                <img src="assets/icons/sqlite.png" alt="Icon" class="icon">
                                <div class="tooltip">SQLITE</div>
                            </a>
                        </div>
                        <div class="title">SQLITE</div>
                    </div>

                  </div>
            </div>
        </div>
    </div>
  </div>
</div>
