<div class="about-page">
    <div class="about-image">
        <img src="assets/manfooter.jpg" alt="Image description">
    </div>
    <div class="about-content">
        <h1 style="margin-bottom: 130px;">Reach out</h1>
        <h1 style="font-size: 34px; margin-bottom: 10px;">Darrel Mendoza</h1>
        <p style="font-weight: bold;">darrelmendoza85@gmail.com</p>

        <div class="links" style="margin-top: 0px;">
            <h1 style="font-weight: bold; font-size: 20px; margin-top: 120px;">CONNECT WITH ME</h1>

            <a href="https://github.com/DarrelMendoza" target="_blank">
                <img src="assets/github.png" alt="GitHub" style="width: 40px; height: 40px; margin-right: 10px;">
            </a>
            <a href="https://www.linkedin.com/in/darrelmendoza/" target="_blank">
                <img src="assets/linkedin.png" alt="LinkedIn" style="width: 43px; height: 43px; margin-right: 10px;">
            </a>
            <a href="https://www.facebook.com/darrel.mendoza.12/" target="_blank">
                <img src="assets/facebook.png" alt="Facebook" style="width: 55px; height: 55px; margin-right: 10px;">
            </a>
            <a href="https://www.instagram.com/darpaaax/" target="_blank">
                <img src="assets/instagram.png" alt="Instagram" style="width: 39px; height: 39px; margin-right: 10px;">
            </a>

            <!-- <a href="https://www.buymeacoffee.com" class="button">
                <img class="button-icon" src="assets/coffee-white.png" alt="Coffee Cup Icon" width="22" height="30"> 
                <span class="cursive-text">Buy me a coffee</span>
            </a> -->
        </div>
        <div class="buy-me-a-coffee">
            <a href="https://www.buymeacoffee.com/darrelmendoza" class="btn btn--liquidBtn">
                <span> <img class="button-icon" src="assets/coffee-white.png" alt="Coffee Cup Icon">
                    Buy me a coffee?</span>
                <div class="btn--liquidBtn--liquid"></div>
            </a>
        </div>
        
    </div>
</div>
