<div class="about-page">
    <div class="about-content">
        <h1>About Me</h1>
        <p> Hi I'm Darrel. I am a passionate Full Stack Engineer with two years of experience in 
            the development industry. I help large corporations scale their product and service by creating and maintaining software applications for both web and mobile devices.
        </p>
        <p>I love applying my experience in software development to help tech companies grow their businesses. I'm fond of reading books about leadership and self improvement. I also like listening to TED talks and always up to date with latest technological trends.</p>
    </div>
    <div class="about-image">
        <img src="assets/mantest.jpg" alt="Image description">
    </div>
</div>
