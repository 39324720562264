<link rel="stylesheet" href="https://unpkg.com/github-calendar@latest/dist/github-calendar-responsive.css">

<div class="container-fluid experience-page">
  <div class="row">
    <div class="col-lg-4 col-xs-5 experience-content">
      <h1>Experience & Responsibilities</h1>
    </div>
    <div class="col-lg-8 col-xs-7 experience-image">
      <div class="job mb-5">
        <div class="d-flex job-info mb-3">
          <h1 class="year mr-5">2023</h1>
          <a href="https://www.talinolabs.com/" class="job-link d-flex">
            <img src="./assets/talino.png" alt="talino" class="job-icon">
            <div class="ml-3 job-details">
              <h1 class="role">FULL STACK ENGINEER</h1>
              <h2 class="company">TALINO VENTURE LABS</h2>
            </div>
          </a>
        </div>
        <p class="description">Instrumental in transforming client business requirements into fully functional 
          applications. Played a significant role at each stage of the software development life cycle, 
          including planning, development, testing, and project reporting. My contributions also extend 
          to developing backend integrations that are tailored to meet specific project needs. Responsible 
          for managing the deployment process across Development, Staging, and Production environments, 
          I ensure that all aspects of the project align seamlessly with client requirements and deliverables. 
          My role at Talino Venture Labs has been pivotal in turning designed mock-ups into robust, scalable solutions.</p>
      </div>

      <div class="job mb-5">
        <div class="d-flex job-info mb-3">
          <h1 class="year mr-5">2023</h1>
          <a href="https://medprojects.com/" class="job-link d-flex">
            <img src="./assets/medprojects.png" alt="medprojects" class="job-icon">
            <div class="ml-3 job-details">
              <h1 class="role">FULL STACK DEVELOPER</h1>
              <h2 class="company">MEDPROJECTS INC.</h2>
            </div>
          </a>
        </div>
        <p class="description">Managed and executed application development and maintenance with a focus on 
          technologies such as Angular, PHP, Laravel, MySQL, and Jasper Reports. I was able to optimize the codebase for improved readability, maintainability, and performance.
          Ensured transparent communication by regularly providing status updates on projects to supervisors, Project Managers, and other team leaders. Proactively 
          addressing a range of codebase challenges, I consistently resolved issues, from bug fixes to design and 
          functional adjustments, contributing to the overall quality and success of the projects.</p>
      </div>

      <div class="job mb-5">
        <div class="d-flex job-info mb-3">
          <h1 class="year mr-5">2022</h1>
          <a href="https://www.sulzer.com/en/" class="job-link d-flex">
            <img src="./assets/sulzer.png"  alt="sulzer" class="job-icon">
            <div class="ml-3 job-details">
              <h1 class="role">ETL CONSULTANT</h1>
              <h2 class="company">SULZER</h2>
            </div>
          </a>
        </div>
        <p class="description">
          Spearheaded a project from its inception, overseeing everything from requirements gathering to production 
          deployment, successfully completing it ahead of the planned timeline. Responsible for executing data 
          solutions, which played a pivotal role in streamlining data delivery processes within the organization. 
          With a focus on big data, I managed the gathering, processing, and analysis of large data sets to meet the 
          company's objectives. Aligning closely with business needs, I ensured the design and delivery of 
          high-quality, accurate data. My skill set also included designing and coding the data warehousing system 
          in accordance with specific business requirements.
        </p>
      </div>

      <div class="job mb-5">
        <div class="d-flex job-info mb-3">
          <h1 class="year mr-5">2021</h1>
          <a href="https://www.globiq.nl/over-ons" class="job-link d-flex">
            <img src="./assets/globiq.png" alt="globiq" class="job-icon">
            <div class="ml-3 job-details">
              <h1 class="role">SOFTWARE DEVELOPER</h1>
              <h2 class="company">GLOBIQ BV PHILS.</h2>
            </div>
          </a>
        </div>
        <p class="description">Pioneered the exploration and adoption of new frameworks and technologies. 
          Not only did It lead the way in incorporating these new tools, but I also headed the training 
          initiatives for other developers, thereby enhancing the company's talent acquisition efforts for 
          outsourcing partners. Working closely with both development and testing teams, I coordinated the 
          creation of high-quality solutions that met or exceeded client expectations in terms of functionality, 
          scalability, and performance. Played a key role in refining the company’s data warehousing systems and 
          demonstrated proficiency in managing database assets effectively.</p>
      </div>
    </div>
  </div>
</div>
